import { useQuery } from '@tanstack/react-query';
import { apiApplicantsV3, apiV2 } from 'services/api';

const getMyAppliations = async (isApplicantsV3: boolean) => {
  const api = isApplicantsV3 ? apiApplicantsV3 : apiV2;
  return await apiV2.get('/applicant/my-applications');
};
export const useMyApplications = (isApplicantsV3 = true, enabled = true, staleTime = 1000 * 60 * 5) => {
  return useQuery({
    queryKey: ['my-appliations'],
    queryFn: () => getMyAppliations(isApplicantsV3),
    enabled,
    staleTime: staleTime, //5 minutes default
  });
};
