import { Box, Icon, Text, Alert } from '@solides/management-util-ui';
import { Badges } from 'components/atoms/Badges';
import { HeaderVacancyPage } from 'components/molecules/HeaderVacancyPage';
import { Vacancy } from 'types/vacancy';
import { Loading } from 'components/atoms';

import { getVacancySalary } from 'utils/vacancy';
import useStore from 'store/index';
import { useAddress } from 'hooks';
import { languagesList } from './languagesList';
import { skillsList } from './skillsList';
import { removingMarginsHtml } from 'utils/format';
import { useMyApplications } from 'queries/applicant';

const Section = ({ title, children }) => (
  <section className="px-4 lg:p-0">
    <Box className="flex flex-col gap-y-4 p-6">
      <h2 className="text-xs font-semibold mb-1">{title}</h2>
      {children}
    </Box>
  </section>
);

interface VacancyDetailsProps extends Vacancy {
  formattedDescription: string[];
}

export const VacancyDetails = (vacancy: VacancyDetailsProps) => {
  const { hardSkills, language, education, currentState, receivingResume } = vacancy;
  const requirements = hardSkills?.concat(language).concat(education);
  const isClosed = currentState !== 'em_andamento' || !receivingResume;

  const { loading, isAuth } = useStore(store => store.session);
  const { data: myApplications, refetch } = useMyApplications(true, isAuth);
  const applications = myApplications?.data?.data?.map(({ id }) => id);
  const { showLocation, showCardAddress, address, simpleAddressWithCountry } = useAddress(vacancy);

  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <>
          <HeaderVacancyPage vacancy={vacancy} applications={applications} onRefetch={refetch} />
          <div className="flex flex-col items-stretch gap-y-4 w-full max-w-6xl mx-auto mt-6 mb-10">
            <div className="px-4 lg:p-0">
              <Alert variant="simple" color="error" closeButton={false} showAlert={isClosed}>
                <p>Esta vaga não aceita mais candidaturas.</p>
              </Alert>
            </div>
            <Section title="Descrição">
              <div className="flex flex-wrap items-center gap-x-6 gap-y-3 text-gray-900">
                <p
                  className="flex items-center gap-x-[6px] text-gray-900 text-caption"
                  data-testid="locale-info"
                >
                  <Icon face="location_on" size="sm" />
                  {showLocation ? simpleAddressWithCountry : 'Local não informado'}
                </p>

                <p className="flex items-center gap-x-2 text-gray-900 text-caption">
                  <Icon face="business_center" size="sm" />{' '}
                  {vacancy?.availablePositions === 1
                    ? '1 posição'
                    : `${vacancy?.availablePositions} posições`}
                </p>

                <p className="flex items-center gap-x-2 text-gray-900 text-caption">
                  <Icon face="monetization_on" size="sm" /> {getVacancySalary(vacancy?.salary)}
                </p>

                {(vacancy?.pcdOnly || vacancy?.peopleWithDisabilities) && (
                  <p className="flex items-center gap-x-2 text-gray-900 text-caption">
                    <Icon face="accessibility_new" size="sm" />
                    {vacancy?.pcdOnly ? 'Exclusivo' : 'Elegível'} para PCD
                  </p>
                )}
              </div>

              <Badges vacancy={vacancy} />

              <span
                data-cy="description"
                className="break-words [&>ul]:list-disc [&>ul]:whitespace-normal [&>ol]:whitespace-normal whitespace-pre-wrap [&>ul]:ml-6 [&>ol]:ml-6 [&>ol]:list-decimal"
                dangerouslySetInnerHTML={{ __html: removingMarginsHtml(vacancy?.description) }}
              />
            </Section>

            {!!requirements.length && (
              <Section title="Requisitos">
                {!!vacancy?.education.length && (
                  <div>
                    <h3 className="font-subtitle1 font-semibold">Escolaridade</h3>
                    <ul className="list-disc pl-6 break-words" data-cy="vacancy-educations">
                      {vacancy?.education.map((requirement, index) => (
                        <li key={requirement.id + index} className="leading-7">
                          {requirement.name}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}

                {!!vacancy?.language.length && (
                  <div>
                    <h3 className="font-subtitle1 font-semibold">Idioma</h3>
                    <ul className="list-disc pl-6 break-words" data-cy="vacancy-languages">
                      {vacancy?.language.map((requirement, index) => (
                        <li key={requirement.id + index} className="leading-7">
                          {requirement.name} {languagesList[requirement.level]}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}

                {!!vacancy?.hardSkills.length && (
                  <div>
                    <h3 className="font-subtitle1 font-semibold">Habilidade Técnica</h3>
                    <ul className="list-disc pl-6 break-words" data-cy="vacancy-hard-skills">
                      {vacancy?.hardSkills.map((requirement, index) => (
                        <li key={requirement.id + index} className="leading-7">
                          {requirement.name} {skillsList[requirement.level]}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </Section>
            )}

            {!!vacancy?.benefits.length && (
              <Section title="Benefícios">
                <ul className="list-disc pl-6 break-words" data-cy="vacancy-benefits">
                  {vacancy?.benefits.map((benefit, index) => (
                    <li key={benefit.id + index} className="leading-7">
                      {benefit.name}
                    </li>
                  ))}
                </ul>
              </Section>
            )}

            {showCardAddress && (
              <Section title="Localização">
                <div className="flex items-center gap-x-2 text-gray-600 break-words" data-cy="how-to-get">
                  <Icon face="location_on" size="sm" />
                  <Text variant="caption" data-testid="how-to-get-location">
                    {address}
                  </Text>
                </div>
              </Section>
            )}
          </div>
        </>
      )}
    </div>
  );
};
