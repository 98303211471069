import { memo } from 'react';
import { Vacancy } from 'types/vacancy';
import { Badge } from '@solides/management-util-ui';
import { jobsTypeOptions } from 'data/filtersOptions';

interface BadgesProps {
  vacancy: Vacancy;
  isLimitSize?: boolean;
}

interface BadgeProps {
  type: string;
  text: string;
}

const ListBadges = ({ isLimitSize = false, vacancy }: BadgesProps): JSX.Element => {
  const result = [] as BadgeProps[];

  if (vacancy?.showModality)
    vacancy?.recruitmentContractType?.map(({ name }) => result.push({ type: 'contract_type', text: name }));

  const jobType = jobsTypeOptions.find(type => type.value === vacancy.jobType);
  jobType && result.push({ type: 'job_type', text: jobType.text });

  vacancy?.seniority?.map(({ name }) => result.push({ type: 'seniority', text: name }));

  if (vacancy?.affirmative && vacancy.affirmative.length !== 0) {
    vacancy.affirmative.forEach(({ name }) => {
      let affirmative: string | string[] = name.split(' ');

      if (affirmative.length >= 6) {
        affirmative = [affirmative[1], affirmative[3], affirmative[4], affirmative[5]].join(' ');
      } else if (affirmative.length >= 5) {
        affirmative = [affirmative[1], affirmative[3], affirmative[4]].join(' ');
      } else {
        affirmative = [affirmative[1], affirmative[3]].join(' ');
      }

      result.push({ type: 'affirmative', text: affirmative });
    });
  }

  if (vacancy?.occupationAreas && vacancy.occupationAreas.length !== 0) {
    if (isLimitSize) {
      const item = vacancy.occupationAreas[0];
      result.push({ type: 'occupation_area', text: item.name });
    } else {
      vacancy.occupationAreas.map(item => {
        result.push({ type: 'occupation_area', text: item.name });
      });
    }
  }

  if (isLimitSize) {
    return (
      <div className="flex flex-wrap gap-2">
        {result.slice(0, 5).map((badge, index) => (
          <Badge
            color="info"
            variant="ghost"
            key={`${badge.text} + ${index}`}
            className="rounded capitalize"
            data-cy={`badges_${badge.type}`}
          >
            {badge.text}
          </Badge>
        ))}

        {result.length > 5 && (
          <Badge
            variant="ghost"
            color="info"
            data-cy="badges_more"
            data-testid="badges_more"
            className="rounded"
          >
            +{result.length - 5}
          </Badge>
        )}
      </div>
    );
  }

  return (
    <div className="flex flex-wrap gap-2">
      {result.map((badge, index) => (
        <Badge
          color="info"
          variant="ghost"
          key={`${badge.text} + ${index}`}
          className="rounded capitalize"
          data-cy={`badges_${badge.type}`}
        >
          {badge.text}
        </Badge>
      ))}
    </div>
  );
};

const Badges = memo(ListBadges);

export { Badges };
