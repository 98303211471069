import { Button, Checkbox, Modal, Text, Toast } from '@solides/management-util-ui';
import Link from 'next/link';
import { useEffect, useState } from 'react';
import { apiApplicantsV3 } from 'services/api';
import useStore from 'store/index';
import { HOST_SETTING_ACCOUNT } from 'utils/constants';
import cookies from 'utils/cookies';

const COOKIE_KEY = 'huntingAcceptModalHasDisplayed';

export const ModalEnableGlobalHunting = () => {
  const [isChecked, setIsChecked] = useState(false);
  const [modal, setModal] = useState<'accept-terms' | 'success-terms'>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showToast, setShowToast] = useState(false);

  const { user, isAuth } = useStore(store => store.session);

  const hasAlreadyShow = cookies.get(COOKIE_KEY) === 'true';

  const handleAccept = async (accept: boolean) => {
    setIsLoading(true);
    try {
      await apiApplicantsV3.patch('/user-settings/toggle-hunting', {
        huntingAccept: accept,
        neverAskHuntingAccept: isChecked,
      });

      setModal(accept ? 'success-terms' : null);
    } catch (error) {
      console.error(error);
      setShowToast(true);
      setModal(null);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isAuth && !hasAlreadyShow) {
      cookies.set(COOKIE_KEY, 'true');

      const { huntingAccept, neverAskHuntingAccept } = user.settings || {};
      if (!huntingAccept && !neverAskHuntingAccept) {
        setModal('accept-terms');
      }
    }
  }, [user, isAuth]);

  if (modal === 'success-terms') {
    return (
      <Modal
        size="md"
        show
        title="Perfil compartilhado com sucesso!"
        showCloseIcon={false}
        confirmButton={
          <Button
            size="md"
            color="primary"
            className="justify-center font-semibold flex-1"
            variant="contained"
            onClick={() => setModal(null)}
          >
            Ok, estou ciente!
          </Button>
        }
      >
        <div className="space-y-5 text-gray-600">
          <Text variant="body1">
            O seu perfil foi compartilhado com os recrutadores que utilizam a plataforma Sólides.
          </Text>

          <Text variant="body1">
            Caso mude de ideia, é possível desativar o seu compartilhamento de conta dentro das suas{' '}
            <Link
              rel="noreferrer"
              target="_blank"
              href={HOST_SETTING_ACCOUNT}
              className="text-info-500 font-semibold underline outline-none"
            >
              configurações
            </Link>
            .
          </Text>
        </div>
      </Modal>
    );
  }

  return (
    <Modal
      show={modal === 'accept-terms'}
      title="Aumente suas chances de ser encontrado"
      size="md"
      showCloseIcon={false}
    >
      <div className="flex flex-col gap-y-5 mb-2">
        <Text>
          Na Sólides, você pode aumentar suas chances de ser encontrado pelos recrutadores através do nosso
          Banco unificado de talentos.
        </Text>

        <Text>
          Permitindo o compartilhamento do seu currículo, as empresas poderão visualizar seus dados e fazerem
          convites para processos seletivos.
        </Text>

        <Text variant="body1">
          Saiba mais sobre compartilhamento de dados em nossos{' '}
          <Link
            download
            href="/solides-termos-de-uso.pdf"
            target="_blank"
            rel="noreferrer"
            className="text-info-500 font-semibold underline outline-none"
          >
            Termos de Uso e política de privacidade.
          </Link>
        </Text>
      </div>

      <Checkbox
        id="dont-show-anymore"
        label="Não me mostre mais isso"
        checked={isChecked}
        onChange={() => setIsChecked(prevState => !prevState)}
      />

      <div className="flex justify-end gap-x-3 mt-8">
        <Button
          size="md"
          color="default"
          variant="outlined"
          onClick={() => handleAccept(false)}
          disabled={isLoading}
        >
          Não permito compartilhar
        </Button>

        <Button
          size="md"
          color="primary"
          variant="contained"
          onClick={() => handleAccept(true)}
          disabled={isLoading}
        >
          Permito compartilhar
        </Button>
      </div>

      <Toast
        closeButton={false}
        color="error"
        renderTime={5000}
        isOpen={showToast}
        title="Algo deu errado!"
        description="Não foi possível completar a ação. Tente novamente mais tarde"
      />
    </Modal>
  );
};
