export const EMOJIS_CODE =
  /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;

export const TYPES_FILTERS = [
  'contractsType',
  'jobsType',
  'seniorities',
  'jobPosition',
  'benefits',
  'occupationAreas',
  'affirmative',
  'pcd',
  'salary',
];

export const TOTAL_VACANCIES = 10;

//export const DOMAIN_COOKIE = { domain: 'solides.jobs' };
export const DOMAIN_COOKIE = { domain: 'solides.com.br' };

export const KEY_USER_STORAGE = 'company-user';

export const JOBS_URLS = {
  local: '.vagas.local.solides.com.br',
  staging: '.vagas.staging.solides.com.br',
  release: '.release.solides.jobs',
  production: '.solides.jobs',
};

export const RELATIONSHIP_RECOMMENDATION = [
  {
    value: 'Supervisionava a pessoa candidata',
    text: 'Supervisionava a pessoa candidata',
  },
  {
    value: 'Era supervisionado(a) pela pessoa candidata',
    text: 'Era supervisionado(a) pela pessoa candidata',
  },
  {
    value: 'Era sênior em relação à pessoa candidata no mesmo grupo',
    text: 'Era sênior em relação à pessoa candidata no mesmo grupo',
  },
  {
    value: 'Era sênior em relação à pessoa candidata em outro grupo',
    text: 'Era sênior em relação à pessoa candidata em outro grupo',
  },
  {
    value: 'A pessoa candidata era sênior em relação a mim no mesmo grupo',
    text: 'A pessoa candidata era sênior em relação a mim no mesmo grupo',
  },
  {
    value: 'A pessoa candidata era sênior em relação a mim em outro grupo',
    text: 'A pessoa candidata era sênior em relação a mim em outro grupo',
  },
  {
    value: 'Era cliente da pessoa candidata',
    text: 'Era cliente da pessoa candidata',
  },
  {
    value: 'A pessoa candidata era minha cliente',
    text: 'A pessoa candidata era minha cliente',
  },
  {
    value: 'Orientava a pessoa candidata',
    text: 'Orientava a pessoa candidata',
  },
  {
    value: 'A pessoa candidata te orientava',
    text: 'A pessoa candidata te orientava',
  },
  {
    value: 'Nós estudávamos na mesma instituição',
    text: 'Nós estudávamos na mesma instituição',
  },
];

export const HOST_SETTING_ACCOUNT = process.env.AUTH_HOST + '/preferences/settings-account';