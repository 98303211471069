import Link from 'next/link';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import { Icon, Text } from '@solides/management-util-ui';
import { IconSolides } from 'assets';

import useStore from 'store';
import { MenuToggle, Button } from 'components/atoms';
import { Notifications } from 'components/molecules';
import { LoginAndAvatarMenu } from './LoginAndAvatarMenu';
import { Vacancy } from 'types/vacancy';
import { handleToAuth } from 'utils/user';
import { useCompany } from 'hooks';

interface INavBarProps {
  vacancy?: Vacancy;
  isExternalPage?: boolean;
}

export const NavBar = ({ vacancy, isExternalPage = false }: INavBarProps) => {
  const { isLoadingCompany } = useCompany();
  const router = useRouter();
  const { isAuth, loading, user, signOut } = useStore(store => store.session);
  const { isEnabled } = useStore(store => store.featuresToggle);
  const { company } = useStore(state => state.company);
  const colorCompany = company?.colorCompany || '';
  const colorTextCompany = company?.colorTextCompany || '#fff';
  const isSticky = router.pathname === '/';
  const isEnabledNotifications = isEnabled('notifications_company');

  const navigation = [
    { name: 'A empresa', url: '/#quem-somos', show: !vacancy?.isHiddenJob },
    { name: 'Banco de talentos', url: '/#banco-de-talentos', show: !vacancy?.isHiddenJob },
  ];

  const [isOpenMenu, setIsOpenMenu] = useState<boolean>(false);

  useEffect(() => {
    const valueOverflow = isOpenMenu ? 'hidden' : 'auto';
    document.body.style.overflow = valueOverflow;
  }, [isOpenMenu]);

  useEffect(() => {
    setIsOpenMenu(false);
    const anchors = ['/#quem-somos', '/#banco-de-talentos'];

    if (anchors.includes(router.asPath)) {
      router.push(router.asPath);
    }
  }, [router.pathname]);

  const handleSignOut = () => {
    signOut();
    setIsOpenMenu(false);
    router.push(process.env.AUTH_HOST);
  };

  const handleNavigation = () => {
    setIsOpenMenu(false);
  };

  return (
    <header
      className={`flex justify-center h-16 md:h-20 bg-white px-4 border-b-gray-500/25 border-b-[1px] ${
        isSticky && 'sticky'
      } top-0 left-0 z-20`}
    >
      <nav className="flex gap-x-6 items-center max-w-6xl w-full">
        <Link href={!vacancy?.isHiddenJob ? '/' : '#'} legacyBehavior>
          <a
            aria-label="Link para a página inicial da empresa"
            className={
              !isExternalPage
                ? 'w-[100px] md:w-[200px] h-[40px] md:h-[60px] mt-2 relative navbar-logo-container mr-auto md:self-end md:mb-2 '
                : 'w-[80px] md:w-[80px] h-[40px] md:h-[60px] mt-2 relative navbar-logo-container mr-auto md:self-end md:mb-2 '
            }
          >
            {!vacancy?.isHiddenJob && company && company?.logo ? (
              <Image
                data-testid="logoCompany"
                className="logo"
                src={company?.logo}
                layout="fill"
                alt={`Logo da empresa ${company?.name}`}
              />
            ) : (
              <IconSolides className="h-[40px] md:h-[60px] w-[100px]" />
            )}
          </a>
        </Link>
        {!isExternalPage && (
          <ul
            className={`fixed sm:relative bottom-0 left-0 w-full sm:w-auto h-[calc(100vh-60px)] z-10 sm:z-0 sm:h-auto bg-white flex flex-col sm:flex-row items-center sm:items-start gap-x-2 px-4 sm:px-0 py-5 sm:pt-0 transition-transform duration-500 translate-x-${
              isOpenMenu ? '0' : 'full'
            } sm:translate-x-0 overflow-y-auto`}
          >
            {navigation.map(navItem =>
              navItem.show ? (
                <li
                  key={navItem.name}
                  className="w-full sm:w-auto p-1 px-4 sm:px-0 sm:p-0"
                  data-cy={`navigation-${navItem.name.toLocaleLowerCase()}`}
                >
                  <Link href={navItem.url} legacyBehavior>
                    <a
                      className="text-body2 text-center font-normal sm:px-4 text-gray-700"
                      onClick={handleNavigation}
                      aria-hidden="true"
                    >
                      {navItem.name}
                    </a>
                  </Link>
                </li>
              ) : (
                ''
              )
            )}

            <li className="w-full block sm:hidden">
              {isAuth ? (
                <div>
                  <hr className="border-0 border-t-[1px] border-t-gray-500/25 mt-1 mb-4 ml-[-15px] mr-[-15px]" />

                  <div className="w-full grid grid-cols-[44px_1fr] items-center gap-x-4 px-4 sm:px-0">
                    <figure className="row-span-2 w-11 h-11 relative rounded-full overflow-hidden">
                      {user.avatar_url ? (
                        <Image
                          src={user.avatar_url}
                          layout="fill"
                          objectFit="cover"
                          objectPosition="center"
                          alt="Avatar"
                        />
                      ) : (
                        <div
                          className={`w-full h-full rounded-full ${
                            colorCompany ? `bg-[${colorCompany}]` : 'bg-primary'
                          } flex items-center justify-center`}
                        >
                          <Icon face="person_outline" className={`text-[${colorTextCompany}] m-auto"`} />
                        </div>
                      )}
                    </figure>

                    <Text variant="subtitle2">{user.name || 'Candidato(a)'}</Text>
                    <Text variant="caption" className="text-gray-600 line-clamp-1 break-words">
                      {user.email}
                    </Text>
                  </div>

                  <div className="flex flex-col gap-y-5 mt-5 px-4 sm:px-0">
                    <Link href={`${process.env.CANDIDATE_HOST}`} legacyBehavior>
                      <a className="w-full flex items-center gap-x-4">
                        <Icon
                          face="account_circle"
                          className={`${colorCompany ? `text-[${colorCompany}]` : 'text-primary'}`}
                        />
                        <Text variant="body2">Perfil</Text>
                      </a>
                    </Link>

                    <Link href={process.env.HOST} legacyBehavior>
                      <a className="w-full flex items-center gap-x-4">
                        <Icon
                          face="public"
                          className={`${colorCompany ? `text-[${colorCompany}]` : 'text-primary'}`}
                        />
                        <Text variant="body2">Portal de vagas</Text>
                      </a>
                    </Link>

                    <Link href={`${process.env.CANDIDATE_HOST}/curriculo`} legacyBehavior>
                      <a className="w-full flex items-center gap-x-4">
                        <Icon
                          face="work_outline"
                          className={`${colorCompany ? `text-[${colorCompany}]` : 'text-primary'}`}
                        />
                        <Text variant="body2">Meu currículo</Text>
                      </a>
                    </Link>

                    <Link href={`${process.env.CANDIDATE_HOST}/meu-profiler`} legacyBehavior>
                      <a className="w-full flex items-center gap-x-4">
                        <Icon
                          face="recent_actors"
                          className={`${colorCompany ? `text-[${colorCompany}]` : 'text-primary'}`}
                          variant="outlined"
                        />
                        <Text variant="body2">Meu Profiler</Text>
                      </a>
                    </Link>

                    <Link href={`${process.env.CANDIDATE_HOST}/applications`} legacyBehavior>
                      <a className="w-full flex items-center gap-x-4">
                        <Icon
                          face="campaign"
                          className={`${colorCompany ? `text-[${colorCompany}]` : 'text-primary'}`}
                          variant="outlined"
                        />
                        <Text variant="body2">Minhas candidaturas</Text>
                      </a>
                    </Link>

                    <Link href={`${process.env.HOST}/minhas-vagas`} legacyBehavior>
                      <a className="w-full flex items-center gap-x-4">
                        <Icon
                          face="favorite_border"
                          className={`${colorCompany ? `text-[${colorCompany}]` : 'text-primary'}`}
                        />
                        <Text variant="body2">Minhas vagas</Text>
                      </a>
                    </Link>

                    <Link href={`${process.env.AUTH_HOST}/preferences/settings-account`} legacyBehavior>
                      <a className="w-full flex items-center gap-x-4">
                        <Icon
                          face="settings"
                          className={`${colorCompany ? `text-[${colorCompany}]` : 'text-primary'}`}
                          variant="outlined"
                        />
                        <Text variant="body2">Configurações</Text>
                      </a>
                    </Link>

                    <button
                      type="button"
                      onClick={handleSignOut}
                      className="w-full flex items-center gap-x-4"
                    >
                      <Icon
                        face="exit_to_app"
                        className={`${colorCompany ? `text-[${colorCompany}]` : 'text-primary'}`}
                      />
                      <Text variant="body2">Sair</Text>
                    </button>
                  </div>
                </div>
              ) : (
                <div className="mt-2 px-4 sm:px-0">
                  <Button
                    onClick={handleToAuth}
                    disabled={loading || isLoadingCompany}
                    isLoading={loading || isLoadingCompany}
                    className="py-2.5 text-[14px]"
                    data-cy="hidden-menu-button-signin"
                    data-test-id="hidden-menu-button-signin"
                    text="Entrar"
                  />
                </div>
              )}
            </li>
          </ul>
        )}
        {!isExternalPage && isEnabledNotifications && isAuth && !isOpenMenu && <Notifications />}
        {!isExternalPage && (
          <>
            <div className="hidden sm:flex">
              <LoginAndAvatarMenu />
            </div>

            <MenuToggle
              className="lg:hidden"
              aria-pressed={isOpenMenu}
              onClick={() => setIsOpenMenu(state => !state)}
            />
          </>
        )}
      </nav>
    </header>
  );
};
