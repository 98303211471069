import { useRouter } from 'next/router';

import { Button } from 'components/atoms';
import { AvatarMenu } from 'components/molecules';
import { NavigationItem } from 'components/molecules/AvatarMenu/types';

import useStore from 'store';
import { handleToAuth, handleRedirectUser } from 'utils/user';
import { useCompany } from 'hooks';

export const LoginAndAvatarMenu = () => {
  const router = useRouter();
  const { isAuth, signOut, user, loading } = useStore(store => store.session);
  const { isLoadingCompany } = useCompany();

  const handleSignOut = () => {
    signOut();

    router.push(process.env.AUTH_HOST);
  };

  const hostSettingAccount = process.env.AUTH_HOST + '/preferences/settings-account';

  const navigation: NavigationItem[] = [
    {
      name: 'Perfil',
      onClick: () => handleRedirectUser(`${process.env.CANDIDATE_HOST}`),
      icon: 'account_circle',
      variant: 'outlined',
    },
    {
      name: 'Portal de vagas',
      onClick: () => handleRedirectUser(`${process.env.HOST}`),
      icon: 'public',
      variant: 'button',
    },
    {
      name: 'Meu currículo',
      onClick: () => handleRedirectUser(`${process.env.CANDIDATE_HOST}/curriculo`),
      icon: 'work_outline',
      variant: 'button',
    },
    {
      name: 'Meu Profiler',
      onClick: () => handleRedirectUser(`${process.env.CANDIDATE_HOST}/meu-profiler`),
      icon: 'recent_actors',
      variant: 'button',
    },
    {
      name: 'Minhas candidaturas',
      onClick: () => handleRedirectUser(`${process.env.CANDIDATE_HOST}/applications`),
      icon: 'campaign',
      variant: 'button',
    },
    {
      name: 'Minhas vagas',
      onClick: () => handleRedirectUser(`${process.env.HOST}/minhas-vagas`),
      icon: 'favorite_border',
      variant: 'button',
    },
    {
      name: 'Configurações',
      icon: 'settings',
      variant: 'button',
      onClick: () => handleRedirectUser(hostSettingAccount),
    },
  ];

  if (isAuth) return <AvatarMenu user={user} handleSignOut={handleSignOut} navigation={navigation} />;

  return (
    <Button
      disabled={loading || isLoadingCompany}
      onClick={handleToAuth}
      className="h-9 px-4 md:px-10 text-[14px]"
      text="Entrar"
      isLoading={loading || isLoadingCompany}
    />
  );
};
