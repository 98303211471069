/* eslint-disable @typescript-eslint/no-explicit-any */
import { User } from 'types/user';
import { api } from 'services/api';
import cookies from './cookies';
import { DOMAIN_COOKIE, KEY_USER_STORAGE } from 'utils/constants';
import { getAppliedVacancies, getFavoriteVacancies } from './vacancy';
import { getAppliedTalents } from './company';
import { getRecommendationBlocks } from './recommendation';

export const DEFAULT_USER = {
  id: 0,
  name: '',
  email: '',
  avatar_url: null,
  vacancies: {
    applications: [],
    favorites: [],
  },
  companies: {
    applications: [],
  },
  recommendation: {
    blocks: [],
  },
};

export const removeUserCookies = (): void => {
  cookies.remove('token', DOMAIN_COOKIE);
  cookies.remove('refreshToken', DOMAIN_COOKIE);
  cookies.remove('idToken', DOMAIN_COOKIE);
  cookies.remove('accessToken', DOMAIN_COOKIE);
  cookies.remove('token_expiration', DOMAIN_COOKIE);
  cookies.remove(window.name, DOMAIN_COOKIE);
  cookies.remove('huntingAcceptModalHasDisplayed', DOMAIN_COOKIE);
};

const getUser = async (): Promise<any> => {
  const userStorage = JSON.parse(sessionStorage.getItem(KEY_USER_STORAGE));

  if (userStorage?.email) {
    return userStorage;
  }

  const response = await api.get('/user');

  return {
    id: response.data.id,
    name: response.data.name,
    email: response.data.email,
    cpf: response.data?.cpf || '',
    avatar_url: response.data?.picture,
    settings: response.data?.settings
  };
};

export const fetchData = async (isApplicantsV3 = false): Promise<User | null> => {
  try {
    const user = await getUser();
    const vacanciesApplications = await getAppliedVacancies(isApplicantsV3);
    const talentsApplications = await getAppliedTalents(isApplicantsV3);
    const favorites = user?.vacancies ? user.vacancies.favorites : await getFavoriteVacancies(user.id);
    const blockedRecommendations = user.email ? await getRecommendationBlocks(user.email) : [];

    cookies.set('userId', user.id);

    return {
      ...user,
      vacancies: {
        favorites,
        applications: vacanciesApplications,
      },
      companies: {
        applications: talentsApplications,
      },
      recommendation: {
        blocks: blockedRecommendations,
      },
    };
  } catch (err) {
    console.log(err);
    removeUserCookies();
  }

  return null;
};

export const getUserData = async (isApplicantsV3 = false): Promise<User> => {
  const token = cookies.get('idToken');

  if (!token) {
    sessionStorage.removeItem(KEY_USER_STORAGE);

    return null;
  }

  api.defaults.headers['Authorization'] = token;

  try {
    const user = await fetchData(isApplicantsV3);

    return user;
  } catch (err) {
    console.log(err);
  }

  return null;
};

export const handleRedirectUser = (url: string): void => {
  const redirect = location.href;
  //cookies.set('redirect_url', redirect, { domain: 'solides.jobs', expires: 0.01 });
  cookies.set('redirect_url', redirect, { domain: 'solides.com.br', expires: 0.01 });
  location.href = url;
};

export const handleToAuth = (): void => {
  handleRedirectUser(process.env.AUTH_HOST + '/');
};
