import { useRouter } from 'next/router';
import { Toast } from '@solides/management-util-ui';
import cookies from 'utils/cookies';
import { createContext, useMemo, useState, useEffect } from 'react';

import useStore from 'store';
import { api } from 'services/api';
import { Vacancy } from 'types/vacancy';
import { applyVacancyInJobs } from 'utils/vacancy';
import { ModalExternalCandidacy, ModalGoToJobs, ModalUpdateCurriculum } from 'components/atoms';
import { ModalDuplicatedAcount } from 'components/atoms/ModalDuplicatedAcount';

export interface ICandidacy {
  vacancy: Vacancy;
  isLoading: boolean;
  applyVacancy(): void;
  showGoToJobs(): void;
}

export interface ICandidacyProps {
  vacancy: Vacancy;
  children: React.ReactNode;
}

const CandidacyContext = createContext({} as ICandidacy);

function CandidacyProvider({ vacancy, children }: ICandidacyProps) {
  const router = useRouter();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [applySuccess, setApplySuccess] = useState<boolean>(false);
  const [applyError, setApplyError] = useState({
    open: false,
    message: '',
  });
  const [modalGoToJobs, setModalGoToJobs] = useState<boolean>(false);
  const [modalUpdateCurriculum, setModalUpdateCurriculum] = useState<boolean>(false);
  const [modalExternalCandidacy, setModalExternalCandidacy] = useState<boolean>(false);
  const [showModalDuplicated, setShowModalDuplicated] = useState<boolean>(false);

  const { appliedVacancy } = useStore(store => store.session);
  const { isEnabled } = useStore(store => store.featuresToggle);

  const applyVacancy = async () => {
    const token = cookies.get('token');
    const isSourceGoogleEnabled = isEnabled('source_google_for_jobs');

    try {
      setIsLoading(true);

      const query = isSourceGoogleEnabled ? `?source=${router.query.utm_source}` : '';

      api.defaults.headers['Authorization'] = token;
      const { data } = await api.post(`/portal-candidature/apply${query}`, {
        vacancyId: vacancy.id,
        metric: router.query?.vacancies_collect_name,
      });

      const { code_request_gestao, code_request_jobs } = data.data;
      const isExternalVacancy = code_request_gestao === 406;
      const isDuplicated = code_request_gestao === 409;
      const isSuccess = code_request_jobs === 200 && code_request_gestao === 200;
      const isUpdateCurriculum = code_request_jobs === 400 || code_request_jobs === 401;
      if (isDuplicated) {
        // Erro: Conta CPF/EMAIL DUPLICADO
        setShowModalDuplicated(true);
      } else if (isExternalVacancy) {
        // Erro: Candidatura externa
        setModalExternalCandidacy(true);
      } else if (isUpdateCurriculum) {
        // Erro: Falta campos obrigatórios
        setModalUpdateCurriculum(true);
      } else if (isSuccess) {
        // Sucesso ao aplicar a vaga
        setApplySuccess(true);
        appliedVacancy(vacancy.id);

        setTimeout(() => setApplySuccess(false), 3000);
      } else {
        // Erro não mapeado
        setApplyError({
          open: true,
          message: data.data?.message,
        });

        setTimeout(() => setApplyError({ open: false, message: '' }), 7000);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const goToCurriculumJobs = () => {
    const redirect = process.env.HOST + router.asPath;
    const source = router.query?.origem;
    const metric = router.query?.vacancies_collect_name;
    applyVacancyInJobs({ vacancy, redirect, source, metric });
  };

  const showGoToJobs = () => setModalGoToJobs(true);

  const values = useMemo(
    () => ({ applyVacancy, showGoToJobs, vacancy, isLoading }),
    [applyVacancy, showGoToJobs, isLoading, vacancy]
  );

  return (
    <CandidacyContext.Provider value={values}>
      <ModalExternalCandidacy
        show={modalExternalCandidacy}
        onClose={() => setModalExternalCandidacy(false)}
      />

      <ModalUpdateCurriculum
        show={modalUpdateCurriculum}
        onConfirm={goToCurriculumJobs}
        onClose={() => setModalUpdateCurriculum(false)}
      />

      <ModalGoToJobs
        show={modalGoToJobs}
        onConfirm={goToCurriculumJobs}
        onClose={() => setModalGoToJobs(false)}
      />
      <ModalDuplicatedAcount show={showModalDuplicated} onClose={() => setShowModalDuplicated(false)} />
      <Toast
        closeButton
        color="success"
        isOpen={applySuccess}
        onClose={() => setApplySuccess(false)}
        title="Candidatura realizada com sucesso!"
      />

      <Toast
        closeButton
        color="error"
        isOpen={applyError.open}
        onClose={() => setApplySuccess(false)}
        title="Ops! Tivemos um problema"
        description={applyError.message}
      />

      {children}
    </CandidacyContext.Provider>
  );
}

export { CandidacyProvider, CandidacyContext };
